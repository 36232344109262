import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import TimePicker from 'react-time-picker';
export default function NewReminder(){
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState('');
    const [reminderText, setReminderText] = useState('');
    const [saveReminderPop, setSaveReminderPop] = useState(false);
    const [value, onChange] = useState('20:00');

    const daysInMonth = (date) => {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    };
  
    const firstDayOfMonth = (date) => {
      return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    };
  
    const renderCalendar = () => {
      const totalDays = daysInMonth(currentDate);
      const startingDay = firstDayOfMonth(currentDate);
      const days = [];
  
      for (let i = 0; i < startingDay; i++) {
        days.push(<div key={`empty-${i}`} className="empty"></div>);
      }
  
      for (let i = 1; i <= totalDays; i++) {
        days.push(
          <div key={i} id={i} onClick={dtVal} className="day">
            {i}
            <span className="reminderCount">9</span>
          </div>
        );
      }
  
      return days;
    };

    function dtVal(e){
      setSelectedDate(e.target.id);
    }
  
    const nextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };
  
    const prevMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const cancelReminder =()=> {
      setSelectedDate(null);
      setReminderText('')
    }

    const saveReminder =()=> {
      setSaveReminderPop(true);
    }

    const closeReminder =()=> {
      setSaveReminderPop(false);
    }
    return(
        <>
          <div className="newReminderPageWrap">
            <div className="calendar">
                <div className="calanderHeader">
                    <button onClick={prevMonth}><FontAwesomeIcon icon={faAngleRight} /></button>
                    <Link to="/MonthlyReminders"><FontAwesomeIcon icon={faAngleRight} /></Link>
                    <h2>      
                      <span>{selectedDate}</span>              
                      <span>{currentDate.toLocaleString('default', { month: 'long' })}{''}</span>
                      <span>{currentDate.getFullYear()}</span>
                    </h2>
                    <button onClick={nextMonth}><FontAwesomeIcon icon={faAngleLeft} /></button>
                </div>
                <div className="days">
                    <div>ראשון</div>
                    <div>שני</div>
                    <div>שלישי</div>
                    <div>רביעי</div>
                    <div>חמישי</div>
                    <div>שישי</div>
                    <div>שבת</div>
                </div>
                <div className="dates">{renderCalendar()}</div>
            </div>
            <div className="setTime">
              <TimePicker onChange={onChange} value={value} />
            </div>
            <div className="addReminderTextWrap">
              <p>הוסף תזכורת חדשה</p>
              <textarea className="addtext" placeholder='הקלד/י' value={reminderText} onChange={(e)=> setReminderText(e.target.value)}></textarea>
            </div>
            <div className="addReminderButtonsWrap">
              <button className='cancel' onClick={cancelReminder}>ביטול</button>
              <button onClick={saveReminder}>שמירה</button>
            </div>

            {
              saveReminderPop ?
              <div className="saveReminderWrap">
                <div className="saveContainer">
                    <h3>התזכורת נשמרה בהצלחה!</h3>
                </div>
                <div className="closeReminderPop" onClick={closeReminder}></div>
              </div>:null
            }
            
          </div>
        </>
    )
}