import RemindersHeader from "../components/remindersHeader";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faPencil } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import calendarIcon from '../assets/images/calendar.svg';
import clockIcon from '../assets/images/clock.svg';
export default function DailyReminders(){

    const [currentDate, setCurrentDate] = useState(new Date());

    const goToNextDate = () => {
      setCurrentDate(prevDate => {
        const newDate = new Date(prevDate);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
      });
    };
  
    const goToPrevDate = () => {
      setCurrentDate(prevDate => {
        const newDate = new Date(prevDate);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
      });
    };

    return(
        <>
        <section className="mwdReminders">            
            <RemindersHeader />
            <div className="mwdRemindersBttn">
                <Link className="active" to='/DailyReminders'>יומי</Link>
                <Link to='/WeeklyReminder'>שבועי</Link>
                <Link to='/MonthlyReminders'>חודשי</Link>
            </div>
            <div className="changeDatesWrap">
                <div className="dateContainer">
                    <button onClick={goToNextDate}><FontAwesomeIcon icon={faAngleRight} /></button>
                        <h2>{currentDate.toDateString()}</h2>
                    <button onClick={goToPrevDate}><FontAwesomeIcon icon={faAngleLeft} /></button>
                </div>
            </div>
            <div className="dayReminders">
                <div className="remindersList">
                    <div className="reminderBox">
                        <p>חתונה של יוסי ומירב אמרלד</p>
                        <div className="remindertxtt">
                            <img src={calendarIcon} alt="" />
                            29/08/24
                        </div>
                        <div className="remindertxtt">
                            <img src={clockIcon} alt="" />
                            10:45
                        </div>
                    </div>
                    <div className="reminderBox">
                        <p>חתונה של יוסי ומירב אמרלד</p>
                        <div className="remindertxtt">
                            <img src={calendarIcon} alt="" />
                            29/08/24
                        </div>
                        <div className="remindertxtt">
                            <img src={clockIcon} alt="" />
                            10:45
                        </div>
                    </div>
                    <div className="reminderBox">
                        <p>חתונה של יוסי ומירב אמרלד</p>
                        <div className="remindertxtt">
                            <img src={calendarIcon} alt="" />
                            29/08/24
                        </div>
                        <div className="remindertxtt">
                            <img src={clockIcon} alt="" />
                            10:45
                        </div>
                    </div>
                    <div className="reminderBox">
                        <p>חתונה של יוסי ומירב אמרלד</p>
                        <div className="remindertxtt">
                            <img src={calendarIcon} alt="" />
                            29/08/24
                        </div>
                        <div className="remindertxtt">
                            <img src={clockIcon} alt="" />
                            10:45
                        </div>
                    </div>
                    <div className="reminderBox">
                        <p>חתונה של יוסי ומירב אמרלד</p>
                        <div className="remindertxtt">
                            <img src={calendarIcon} alt="" />
                            29/08/24
                        </div>
                        <div className="remindertxtt">
                            <img src={clockIcon} alt="" />
                            10:45
                        </div>
                    </div>
                    <div className="reminderBox">
                        <p>חתונה של יוסי ומירב אמרלד</p>
                        <div className="remindertxtt">
                            <img src={calendarIcon} alt="" />
                            29/08/24
                        </div>
                        <div className="remindertxtt">
                            <img src={clockIcon} alt="" />
                            10:45
                        </div>
                    </div>
                </div>
            </div>
                <div className="fixedBttnsWrap">
                    <Link className="editReminder" to='/NewReminder'><FontAwesomeIcon icon={faPencil} /></Link>
                    <Link className='microPhone' to="/RecordReminder"></Link>
                </div>
        </section>
        </>
    )
}