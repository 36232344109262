import Logo from '../assets/images/kukuLogo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraAlt } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import cameraIcon from '../assets/images/camera.svg';
import imageIcon from '../assets/images/image.svg';
export default function CreateProfile() {
    const navigate = useNavigate();
    const [capturedMedia, setCapturedMedia] = useState(null);
    const [profilePicturePop, setProfilePicturePop] = useState(false);

    const handleCapture = (event) => {
      const file = event.target.files[0];
      if (file) {
        setCapturedMedia(URL.createObjectURL(file));
      }
    };

    const [isFocused, setIsFocused] = useState(false);
    const [name, setName] = useState('');

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const handleChange = (e) => setName(e.target.value);

    const submit =()=> {
        if(name === '') { 
            alert('Enter Name')
        }
        else {
            navigate(`/UserDetails?value=${encodeURIComponent(name)}`);
        }
    }

    const chooseImage =()=> {
        setProfilePicturePop(true);
    }

    const closeOptionPop =()=> {
        setProfilePicturePop(false);
    }


    return(
        <>
            <section className="loginSectionsTotalWrap">
                <div className="topSection">
                    <span className="logo"><img src={Logo} alt="" /></span>
                </div>
                <div className="selectProfilePicture">
                    <picture>
                        {capturedMedia && <img src={capturedMedia} alt="Captured" />}
                        <span className='chooseImage' onClick={chooseImage}><FontAwesomeIcon icon={faCameraAlt} /></span>
                    </picture>
                </div>

                {
                    profilePicturePop ?
                    <div className="ppOptionPop">
                        <div className="ppOptionContent">
                            <h4>עריכת תמונת פרופיל</h4>
                            <label className='optionSelect'>
                                <input type="file" accept="image/*" capture="environment" onChange={handleCapture} />
                                <span><img src={cameraIcon} alt="" /></span>
                                מצלמה
                            </label>
                            <label className='optionSelect'>
                                <input type="file" accept="image/*" onChange={handleCapture} />
                                <span><img src={imageIcon} alt="" /></span>
                                גלריה
                            </label>
                        </div>
                        <div className="closeOptPop" onClick={closeOptionPop}></div>
                    </div>:null
                }

                <div className="inputsWrap">
                    <div className="inputWrap nameInput">
                        <input type="text" className="inpUsr" value={name} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} placeholder=" " id="phoneNumber" />
                        <label htmlFor="phoneNumber" className={isFocused || name ? 'active label' : 'label'}>שם מלא</label>
                    </div>
                </div>
                <button className='subBttn createProfile' onClick={submit}>סיום</button>
            </section>
        </>
    )
}