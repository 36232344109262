import Logo from '../assets/images/kukuLogo.png';
import {useLocation, useNavigate} from 'react-router-dom';
export default function UserDetails(){
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const name = params.get('value');
    function nextPage(){
        navigate('/Reminders')
    }
    return(
        <>
            <section className="loginSectionsTotalWrap">
                <div className="topSection">
                    <span className="logo"><img src={Logo} alt="" /></span>
                </div>
                <div className="userDetails">
                    <picture></picture>
                    <h4>
                        היי {name}, <br />
                        ברוכים הבאים לקהילה שלנו <br />
                        כיף שהצטרפת:)
                    </h4>
                </div>
                <button className='subBttn' onClick={nextPage}>המשך</button>
            </section>
        </>
    )
}