import birdImage from '../assets/images/bird.png';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
export default function Reminders(){
    const date = new Date();
    const currentDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;  
    return(
        <>
            <section className="remindersWrap">
                <h2>תזכורות</h2>
                <h4>יום חמישי  {currentDate}</h4>
                <picture className="birdImg">
                    <img src={birdImage} alt="" />
                </picture>
                <Link className='addReminder'>האם תרצה להוסיף תזכורת?</Link>
                <div className="fixedBttnsWrap">
                    <Link className="editReminder" to='/NewReminder'><FontAwesomeIcon icon={faPencil} /></Link>
                    <Link className='microPhone' to="/RecordReminder"></Link>
                </div>
            </section>
        </>
    )
}