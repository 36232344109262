import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faPencil } from "@fortawesome/free-solid-svg-icons";
import RemindersHeader from "../components/remindersHeader";
import { Link } from "react-router-dom";
import calendarIcon from '../assets/images/calendar.svg';
import clockIcon from '../assets/images/clock.svg';
export default function MonthlyReminders(){
    const [currentDate, setCurrentDate] = useState(new Date());

    const daysInMonth = (date) => {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    };
  
    const firstDayOfMonth = (date) => {
      return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    };
  
    const renderCalendar = () => {
      const totalDays = daysInMonth(currentDate);
      const startingDay = firstDayOfMonth(currentDate);
      const days = [];
  
      for (let i = 0; i < startingDay; i++) {
        days.push(<div key={`empty-${i}`} className="empty"></div>);
      }
  
      for (let i = 1; i <= totalDays; i++) {
        days.push(
          <Link to="/NewReminder" key={i} id={i} className="day">
            {i}
            <span className="reminderCount">9</span>
          </Link>
        );
      }
  
      return days;
    };
  
    const nextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };
  
    const prevMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };
    return(
        <>
            <section className="mwdReminders">
                <RemindersHeader />
                <div className="mwdRemindersBttn">
                    <Link to='/DailyReminders'>יומי</Link>
                    <Link to='/WeeklyReminder'>שבועי</Link>
                    <Link className="active" to='/MonthlyReminders'>חודשי</Link>
                </div>
                
                <div className="calendar">
                    <div className="calanderHeader">
                        <button onClick={prevMonth}><FontAwesomeIcon icon={faAngleRight} /></button>
                        <h2>                 
                        <span>{currentDate.toLocaleString('default', { month: 'long' })}{''}</span>
                        <span>{currentDate.getFullYear()}</span>
                        </h2>
                        <button onClick={nextMonth}><FontAwesomeIcon icon={faAngleLeft} /></button>
                    </div>
                    <div className="days">
                        <div>ראשון</div>
                        <div>שני</div>
                        <div>שלישי</div>
                        <div>רביעי</div>
                        <div>חמישי</div>
                        <div>שישי</div>
                        <div>שבת</div>
                    </div>
                    <div className="dates">{renderCalendar()}</div>
                </div>

                <div className="dayReminders">
                    <h4>29/08 יום שלישי</h4>
                    <div className="remindersList">
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fixedBttnsWrap">
                    <Link className="editReminder" to='/NewReminder'><FontAwesomeIcon icon={faPencil} /></Link>
                    <Link className='microPhone' to="/RecordReminder"></Link>
                </div>
            </section>
        </>
    )
}