import Logo from '../assets/images/kukuLogo.png';
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
export default function PhoneNumber(){
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState('');

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const handleChange = (e) => setValue(e.target.value);

    const navigate = useNavigate();
    function submit(){
        navigate(`/VerificationCode?value=${encodeURIComponent(value)}`);
    }
    return(
        <>
            <section className="loginSectionsTotalWrap">
                <div className="topSection">
                    <span className="logo"><img src={Logo} alt="" /></span>
                    <p>היי, ברוכים הבאים לkuku <br /> הזן/י את מספר הטלפון על מנת להתחבר</p>
                </div>
                <div className="inputsWrap">
                    <div className="inputWrap">
                        <input type="number" className="inpUsr" value={value} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} placeholder=" " id="phoneNumber" />
                        <label htmlFor="phoneNumber" className={isFocused || value ? 'active label' : 'label'}>מספר</label>
                    </div>
                    <div className="inputWrap areacode">
                        <label htmlFor="areaCode" className='valid'>קידומת</label>
                        <select className="inpUsr" id="areaCode">
                            <option>+972</option>
                        </select>
                    </div>  
                </div>
                <label className='conditionsCheck'>
                    <input type="checkbox" />
                    <span className="checkMark"><FontAwesomeIcon icon={faCheck} /></span>
                    <b>הנך מאשר/ת את תנאי השימוש והפרטיות</b>
                </label>
                <button className='subBttn' onClick={submit}>שלח לי קוד אימות</button>
            </section>
        </>
    )
}