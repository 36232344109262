import Logo from '../assets/images/kukuLogo.png';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
export default function VerificationCode(){
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState('');
    const [hideText, setHideText] = useState(true);
  
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const handleChange = (e) => setValue(e.target.value);

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const phoneNumber = params.get('value');
    const animationRef = useRef(null);

    function submitOtp(){
        if(value !== '12345') { 
            setHideText(false);
            animationRef.current.classList.add('notMatched');
        }
        else {
            navigate('/CreateProfile')
        }
    }
    return(
        <>
        <section className="loginSectionsTotalWrap">
            <div className="topSection">
                <span className="logo"><img src={Logo} alt="" /></span>
                { hideText ?
                    <p>שלחנו לך קוד אימות לטלפון-{phoneNumber} <br />
                    הזן/י את הקוד שקיבלת</p>:
                    <div className='alertBox'>
                        <p>הקוד שהוזן אינו תקין </p>
                        <h4>הזן/י את הקוד שוב</h4>
                    </div>
                }
            </div>
            <div className="inputsWrap">
                <div ref={animationRef} className="inputWrap otp">
                    <input type="number" className="inpUsr" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} placeholder=" " id="phoneNumber" />
                    <label htmlFor="phoneNumber" className={isFocused || value ? 'active label' : 'label'}>קוד אימות</label>
                </div> 
            </div>
            <div className="otpNotSent">
                <Link>לא קיבלתי קוד</Link>
            </div>
            <button className='subBttn' onClick={submitOtp}>אישור</button>
        </section>
        </>
    )
}