import searchIcon from '../assets/images/searchIcon.svg';
import { Link } from 'react-router-dom';
import { useState, useRef } from 'react';
export default function RemindersHeader(){
    const [openSideBar, setOpenSideBar] = useState(false);
    const animateRef = useRef();
    const activeSideBar =()=> {
        setOpenSideBar(true);        
        setTimeout(() => {
            animateRef.current.classList.add('open');
        }, 10);
    }
    const closeAsideBar =()=> {
        setOpenSideBar(false);  
        animateRef.current.classList.remove('open');
    }
    return(
        <>
            <div className="mwdReminderTopHeader" ref={animateRef}>
                <Link className='goSearch' to='/Search'><img src={searchIcon} alt="" /></Link>
                <picture className='profilePic' onClick={activeSideBar}></picture>
                {
                    openSideBar ?
                    <aside>
                        <ul className='navLinks'>
                            <li><Link>התחברות/הרשמה</Link></li>
                            <li><Link>עריכת פרטי חשבון</Link></li>
                            <li><Link>הגדרות</Link></li>
                            <li><Link>עזרה ותמיכה</Link></li>
                            <li><Link>צור קשר</Link></li>
                            <li><Link to="/Terms">תנאי שימוש ופרטיות</Link></li>
                            <li><Link to="/SocialNetworks">אודות קוקו</Link></li>
                        </ul>
                        <div className="closeAside" onClick={closeAsideBar}></div>
                    </aside>:null
                }
            </div>
        </>
    )
}