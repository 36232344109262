import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import trashIcon from "../assets/images/trash.svg";
import save from "../assets/images/save.svg";
import pauseIcon from "../assets/images/pause.svg";
import editIcon from "../assets/images/editIcon.svg";
import trashCan from "../assets/images/trashCan.gif";
export default function RecordReminder(){
    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const [deleteAlertPop, setDeleteAlertPop] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);  
    const playRef = useRef();

    const startRecording = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };
  
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioURL(audioUrl);
          audioChunksRef.current = [];
        };
  
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    };
  
    const stopRecording = () => {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
      }
    };
    const playRecord = () => {
      if (playRef.current) {
        playRef.current.play();
      }
    };
    const pauseRecord = () => {
      if (playRef.current) {
        playRef.current.pause();
      }
    };

    const deleteRecord =()=> {
      setAudioURL(null);
      setDeleteAlertPop(false);
      setDeleted(true);
      setTimeout(() => {
        setDeleted(false);
      }, 2000);
    }

    const sureToDelete =()=> {
      setDeleteAlertPop(true);
    }

    const cancelDelete =()=> {
      setDeleteAlertPop(false);
    }

    const saveAudio =()=> {
      const link = document.createElement('a');
      link.href = audioURL;
      link.download = 'recorded_audio.wav';
      link.click();
    }

    const [showSubmitBttn, setShowSubmitBttn] = useState(false);
    const navigate = useNavigate();

    function haveValue(e){
      if(e.target.value !== ''){
        setShowSubmitBttn(true);
      }
      else{
        setShowSubmitBttn(false);
      }
    }


    return(
        <>
          <section className="recordCompleteWrap">
            
              {
                isRecording ?
                <div className="recordingAlert">
                  KUKU Record Your Sound
                </div>:null
              }

              <h2>הקלט תזכורת חדשה</h2>              
              <span className="backBtn" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faAngleRight} /></span>
              {
                audioURL ?
                <button className='recordAudio playRecord' onClick={playRecord}></button>:
                <button className={isRecording ? 'recordAudio startRecord' :'recordAudio'} onClick={isRecording ? stopRecording : startRecording}></button>
              }

              {
                audioURL ?
                <div className='audioMain'>
                  <audio src={audioURL} ref={playRef} controls />
                </div>:null
              }

              {
                ! audioURL ?
                <div className="playerBox"></div>:
                <div className="playerBox playing"></div>
              }
              
              <div className="controlBttnsWrap">
                <button onClick={sureToDelete}><img src={trashIcon} alt="" /> מחק</button>
                <button onClick={pauseRecord}><img src={pauseIcon} alt="" /> השהה</button>
                <button onClick={saveAudio}><img src={save} alt="" />שמור</button>
              </div>

              {
                deleteAlertPop?
                <div className="deleteAlertWrap">
                  <div className="deletePopContainer">
                    <img src={trashCan} alt="" />
                    <h3>האם אתה בטוח שברצונך למחוק את התזכורת?</h3>
                    <div className="deletePopBttns">
                      <button className='cancelBtn' onClick={cancelDelete}>לא</button>
                      <button onClick={deleteRecord}>כן</button>
                    </div>
                  </div>
                  <div className="cancelDeleteOverlay" onClick={cancelDelete}></div>
                </div>:null
              }

              {
                deleted ?
                <div className="deletedPopWrap">
                  <div className="deletedPopContainer">
                    <img src={trashCan} alt="" />
                    <h3>התזכורת נמחקה</h3>
                  </div>
                </div>:null
              }

              <div className="textBoxWrap">
                <textarea className="textArea" onChange={haveValue}></textarea>
                {
                  showSubmitBttn ?
                  <button>שמירה</button>:null
                }                
              </div>
              <Link to="/NewReminder" className='addReminder'><img src={editIcon} alt="" /> כתוב תזכורת חדשה</Link>
          </section>
        </>
    )
}