import { Route, Routes } from "react-router-dom";
import PhoneNumber from './pages/PhoneNumber';
import VerificationCode from "./pages/VerificationCode";
import CreateProfile from "./pages/CreateProfile";
import UserDetails from "./pages/UserDetails";
import Reminders from "./pages/Reminders";
import RecordReminder from "./pages/RecordReminder";
import NewReminder from "./pages/NewReminder";
import SocialNetwork from "./pages/SocialNetworks";
import Terms from "./pages/Terms";
import Search from "./pages/Search";
import MonthlyReminders from "./pages/MonthlyReminders";
import WeeklyReminder from "./pages/WeeklyReminder";
import DailyReminders from "./pages/DailyReminders";

import './assets/css/loginScreensStyle/loginStyle.css';
import './assets/css/recordReminderStyle/reminders.css';
import './assets/css/addReminders/addReminder.css';
import './assets/css/socialNetworks/socialNetworks.css';
import './assets/css/searchStyles/search.css';
import './assets/css/termsStyle/termsStyle.css';
import './assets/css/allReminders/allStyle.css';
function App() {
  return (
    <>  
      <Routes>
        <Route path="*" element={<PhoneNumber />} />
        <Route path="/" element={<PhoneNumber />} />
        <Route path="/VerificationCode" element={<VerificationCode />} />
        <Route path="/CreateProfile" element={<CreateProfile />} />
        <Route path="/userDetails" element={<UserDetails />} />
        <Route path="/Reminders" element={<Reminders />} />
        <Route path="/RecordReminder" element={<RecordReminder />} />
        <Route path="/NewReminder" element={<NewReminder />} />
        <Route path="/SocialNetworks" element={<SocialNetwork />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Search" element={<Search />} />
        <Route path="/MonthlyReminders" element={<MonthlyReminders />} />
        <Route path="/WeeklyReminder" element={<WeeklyReminder />} />
        <Route path="/DailyReminders" element={<DailyReminders />} />
      </Routes>
    </>
  );
}

export default App;