import RemindersHeader from "../components/remindersHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import calendarIcon from '../assets/images/calendar.svg';
import clockIcon from '../assets/images/clock.svg';
export default function WeeklyReminder(){
    const [weekDays, setWeekDays] = useState([]);
    const [activeDay, setActiveDay] = useState(null);
    const currentDate = new Date();
    const monthNames = [
      'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
      'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
    ];
  
    const currentMonth = monthNames[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();
    useEffect(() => {
        const getCurrentWeekDays = () => {
          const currentDate = new Date();
          const currentDay = currentDate.getDay();
          const weekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDay));
          
          const days = [];
          for (let i = 0; i < 7; i++) {
            const day = new Date(weekStart);
            day.setDate(weekStart.getDate() + i);
            days.push({
              date: day.getDate(),
              dayName: day.toLocaleDateString('en-US', { weekday: 'short' })
            });
          }
          
          setWeekDays(days);
        };    
        getCurrentWeekDays();
    }, []);

    const handleClick = (active) => {
        setActiveDay(active);
    };
    return(
        <>
            <section className="mwdReminders">
                <RemindersHeader />
                <div className="mwdRemindersBttn">
                    <Link to='/DailyReminders'>יומי</Link>
                    <Link className="active" to='/WeeklyReminder'>שבועי</Link>
                    <Link to='/MonthlyReminders'>חודשי</Link>
                </div>
                <div className="currentMonth">
                    <Link to="/MonthlyReminders"><span>{currentYear}</span> <span>{currentMonth}</span> </Link>
                </div>
                <ul className="weekDaysList">
                    {weekDays.map((day, active) => (
                        <li key={active} className={activeDay === active ? 'active' : ''} onClick={() => handleClick(active)}><span>{day.date}</span> <span>{day.dayName}</span></li>
                    ))}
                </ul>

                <div className="dayReminders">
                    <div className="remindersList">
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                        <div className="reminderBox">
                            <p>חתונה של יוסי ומירב אמרלד</p>
                            <div className="remindertxtt">
                                <img src={calendarIcon} alt="" />
                                29/08/24
                            </div>
                            <div className="remindertxtt">
                                <img src={clockIcon} alt="" />
                                10:45
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fixedBttnsWrap">
                    <Link className="editReminder" to='/NewReminder'><FontAwesomeIcon icon={faPencil} /></Link>
                    <Link className='microPhone' to="/RecordReminder"></Link>
                </div>
            </section>
        </>
    )
}