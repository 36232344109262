import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons/faMicrophone";
export default function Search(){
    return(
        <>
            <section className="searchWrap">
                <div className="searchBoxWrap">
                    <button className="goBack"><FontAwesomeIcon icon={faAngleRight} /></button>
                    <input type="text" placeholder="חיפוש" className="search" />
                    <button className="voiceSearch"><FontAwesomeIcon icon={faMicrophone} /></button>
                </div>
            </section>
        </>
    )
}