import aboutKukuLogo from '../assets/images/kukuAbout.png'
import { Link } from 'react-router-dom'
import facebookIcon from '../assets/images/facebook.svg'
import instaIcon from '../assets/images/instagram.svg'
import whatsappIcon from '../assets/images/whatsapp.svg'
import gmailIcon from '../assets/images/gmail.svg'
export default function SocialNetwork(){
    return(
        <>
            <section className="socialMediaCompleteWrap">
                <div className="kukuSmLogo">
                    <img src={aboutKukuLogo} alt="" />
                </div>
                <div className="socialNetworksBlok">
                    <h4>חפשו אותנו ברשתות החברתיות</h4>
                    <div className="iconsBox">
                        <Link><img src={instaIcon} alt="" /></Link>
                        <Link><img src={whatsappIcon} alt="" /></Link>
                        <Link><img src={facebookIcon} alt="" /></Link>
                        <Link><img src={gmailIcon} alt="" /></Link>
                    </div>
                </div>
            </section>
        </>
    )
}